import styled from "styled-components";

export const StatsDiv = styled.div`
    max-width: 250px;
    width: 33%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
`